<template>
    <div :class="s.container" @click="$store.dispatch('toggleTheme')">
        <Icon
            v-if="$store.getters.isThemeDark"
            icon="light_on"
        />
        <Icon
            v-else
            icon="light_off"
            :class="s.violet"
        />
    </div>
</template>

<script>
import Icon from 'UI/Icon.vue';

export default {
    name: 'Auth.ToggleTheme',
    components: {
        Icon,
    },
};
</script>

<style lang="postcss" module="s">
.container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 25px;
    right: 25px;
    width: 43px;
    height: 43px;
    background: var(--main-block-color);
    border-radius: 50%;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    border: none;
    outline: none;
    cursor: pointer;
    z-index: 50;
    & .violet {
        color: var(--cl-violet);
    }
}
</style>
