<template>
    <div :class="theme.auth.auth">
        <ToggleTheme/>
        <div :class="theme.auth.subcontainer">
            <form @submit.prevent="confirm">
                <LogoBlock/>
                <div :class="theme.auth.header">
                    <span :class="theme.auth.text">
                        Code Security Authentication
                    </span>
                </div>
                <div :class="theme.auth.content">
                    <p :class="[theme.auth.mbL, s.center]">
                        <Icon icon="code_totp" :class="s.violet" />
                    </p>
                    <p :class="[theme.auth.mbL, s.grayText]">
                        Enter 6 digit code from Google Authenticator
                    </p>
                    <SeparatedDigitsInput
                        :class="theme.auth.mbL"
                        id-prefix="code"
                        :number-of-digits="6"
                        :onChange="onChange"
                        autofocus
                    />
                    <Button
                        is-submit
                        button-type="primary"
                        :button-disabled="isMfaSubmitDisabled"
                        wide
                        :class="s.mbS"
                    >
                        <template>
                            Verify
                        </template>
                    </Button>
                    <Button
                        @click="$router.push('/signin')"
                        button-type="infoNoBackground"
                        wide
                        :class="s.cancelButton"
                    >
                        <template>
                            cancel
                        </template>
                    </Button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ToggleTheme from '@/components/Auth/components/ToggleTheme.vue';
import LogoBlock from '@/components/Auth/components/LogoBlock.vue';
import { getActivePresets, getPresetsList, makeDefaultPresets } from 'Store/v2/Presets';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import { getErrorMessage } from 'Store/modules/Auth';
import theme from 'Theme';
import SeparatedDigitsInput from 'Control/SeparatedDigitsInput.vue';
import Button from 'Control/Button.vue';
import Icon from 'UI/Icon.vue';

export default {
    name: 'Auth.SignIn.Confirm',
    components: {
        ToggleTheme,
        LogoBlock,
        SeparatedDigitsInput,
        Button,
        Icon,
    },
    data() {
        return {
            otpCode: '',
            isMfaSubmitDisabled: false,
            theme,
        };
    },
    computed: {
        ...mapGetters({
            isMfaFactorActive: 'Auth/isMfaFactorActive',
            isEmailVerified: 'Auth/isEmailVerified',
        }),
    },
    methods: {
        async confirm() {
            if (this.otpCode.length !== 6) {
                await this.$store.dispatch('Notificator/showErrorNotification', 'Please enter 6 digits code');
                return;
            }

            this.isMfaSubmitDisabled = true;
            this.$store.commit(SET_LOADING_ON(undefined));

            try {
                const data = await this.$store.dispatch('Auth/confirmLogin', {
                    answer: this.otpCode,
                });
                if (data && data.isMfaChallengeFinished === false) {
                    this.otpCode = '';
                    await this.$store.dispatch('Notificator/showSuccessNotification', "It's OK. Please, confirm the otp code one more time");
                } else if (!this.isEmailVerified) {
                    await this.$router.push(`/confirm-signup?email=${this.profileEmail}`);
                } else {
                    await this.$store.dispatch('User/getCurrentUser');
                    await this.$store.dispatch(makeDefaultPresets(undefined));
                    await this.$store.dispatch(getPresetsList(undefined));
                    await this.$store.dispatch(getActivePresets(undefined));
                    await this.$store.dispatch('initInterface');
                    await this.$router.push('/terminal');
                }
            } catch (error) {
                const { errorMessage, needLogOut } = getErrorMessage(error);
                await this.$store.dispatch('Notificator/showErrorNotification', errorMessage);
                if (needLogOut) {
                    try {
                        await this.$store.dispatch('Auth/logout', undefined);
                        await this.$router.push('/signin');
                    } catch {
                        document.location.reload();
                    }
                }
            } finally {
                this.isMfaSubmitDisabled = false;
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        onChange(value) {
            this.otpCode = value;
        },
    },
    created() {
        if (!this.isMfaFactorActive) {
            this.$router.replace('/signin');
        }
    },
};
</script>

<style lang="postcss" module="s">
.goBack {
    position: absolute;
    top: -18px;
    font-weight: var(--fw-bold);
    font-size: var(--fs-l);
    line-height: var(--fs-l);
    color: var(--cl-violet);
    cursor: pointer;
    margin-bottom: var(--m-s);
}
.violet {
    color: var(--cl-violet);
    width: 50px;
    height: 86px;
}
.center {
    text-align: center;
}
.grayText {
    font-weight: var(--fw-semibold);
    font-size: var(--fs-l);
    line-height: var(--fs-l);
    color: var(--cl-gray);
    text-align: center;
}
.mbS {
    margin-bottom: var(--m-s);
}
.cancelButton {
    font-size: var(--fs-m) !important;
    line-height: var(--fs-m) !important;
}
</style>
