<template>
    <div :class="{ [s.container]: isAbsolute }">
        <img
            v-if="$store.getters.isThemeDark"
            src="@/assets/images/icons/LogoDark.svg"
        />
        <img
            v-else
            src="@/assets/images/icons/LogoLight.svg"
        />
    </div>
</template>

<script>
export default {
    name: 'LogoBlock',
    props: {
        isAbsolute: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style lang="postcss" module="s">
.container {
    position: absolute;
    top: -175px;
    left: 50%;
    transform: translate(-50%, 0);
}
</style>
