
import Vue from 'vue';

interface Methods {
    onInput: (data: { index: number; value: any; }) => void;
    onLeft: (data: { index: number; }) => void;
    onRight: (data: { index: number; }) => void;
    onPaste: (data: { value: any; }) => void;
    getValue: () => string;
}

export default Vue.extend<unknown, Methods, unknown, any>({
    props: {
        numberOfDigits: {
            type: Number,
            required: true,
        },
        onChange: {
            type: Function,
            required: true,
        },
        idPrefix: {
            type: String,
            required: true,
        },
        hasErrors: {
            type: Boolean,
            default: false,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onInput({ index, value }) {
            (document.getElementById(`${this.idPrefix}${index}`) as any)!.value = Number(String(value.data).replace(/\D/g, ''));
            const currentElement: any = document.getElementById(`${this.idPrefix}${index}`);
            if (value.type === 'keydown' && value.key === 'Backspace') {
                currentElement.value = '';
                const prevElement = (document.getElementById(`${this.idPrefix}${index - 1}`) as any);
                const prevElementValue = prevElement ? prevElement.value : '';
                if (index !== 1) {
                    prevElement.focus();
                    // setTimeout нужен чтобы значение в предыдущем инпуте не менялось на 0
                    setTimeout(() => {
                        prevElement.value = prevElementValue;
                    }, 1);
                }
                this.onChange(this.getValue(), this.idPrefix);
                return;
            }
            if (value.type === 'keydown' && value.key === 'Delete') {
                currentElement.value = '';
                const nextElement = (document.getElementById(`${this.idPrefix}${index + 1}`) as any);
                const nextElementValue = nextElement ? nextElement.value : '';
                if (index !== this.numberOfDigits) {
                    nextElement.focus();
                    // setTimeout нужен чтобы значение в предыдущем инпуте не менялось на 0
                    setTimeout(() => {
                        nextElement.value = nextElementValue;
                    }, 1);
                }
                this.onChange(this.getValue(), this.idPrefix);
                return;
            }
            if (value.data === null || !/^[0-9]+$/.test(value.data)) {
                currentElement.value = '';
                this.onChange(this.getValue(), this.idPrefix);
                return;
            }
            if (String(currentElement.value)) {
                currentElement.value = value.data;
            }
            if (index !== this.numberOfDigits) {
                document.getElementById(`${this.idPrefix}${index + 1}`)!.focus();
            }
            this.onChange(this.getValue(), this.idPrefix);
        },
        onLeft({ index }) {
            if (index !== 1) {
                document.getElementById(`${this.idPrefix}${index - 1}`)!.focus();
            }
        },
        onRight({ index }) {
            if (index !== this.numberOfDigits) {
                document.getElementById(`${this.idPrefix}${index + 1}`)!.focus();
            }
        },
        onPaste({ value }) {
            value.preventDefault();
            const pasteValue: string = value.clipboardData.getData('text/plain');
            const symbolsArray: string[] = pasteValue.split('');
            if (pasteValue && symbolsArray.length === this.numberOfDigits) {
                for (let i = 1; i <= this.numberOfDigits; i += 1) {
                    const currentElement: any = document.getElementById(`${this.idPrefix}${i}`);
                    currentElement.value = symbolsArray[i - 1];
                }
                this.onChange(this.getValue(), this.idPrefix);
                return;
            }
            for (let i = 1; i <= this.numberOfDigits; i += 1) {
                (document.getElementById(`${this.idPrefix}${i}`) as HTMLInputElement)!.value = '';
            }
            this.onChange(this.getValue(), this.idPrefix);
        },
        getValue() {
            let result = '';
            for (let i = 1; i <= this.numberOfDigits; i += 1) {
                result += (document.getElementById(`${this.idPrefix}${i}`) as any).value;
            }
            return result;
        },
    },
});
